body {
  line-height: 1;
}

:root {
  --primary-color: #00d473;
  --error-color: #f44336;
}

/* react-phone-input-2 dropdown override */
.flag-dropdown::before {
  color: rgba(0, 0, 0, 0.55) !important;
  content: 'Org Phone Number' !important;
  cursor: text;
  font-family: 'Poppins', 'Roboto' !important;
  font-size: 0.75em !important;
  font-weight: 400 !important;
}

.react-tel-input .form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

/* .react-tel-input .form-control.invalid-number {
  border: inherit !important;
} */

.react-tel-input .form-control:focus + div:before {
  color: var(--primary-color) !important;
}

.react-tel-input .form-control.react-phone-input-error + div:before {
  color: var(--error-color) !important;
}

.react-tel-input .form-control.react-phone-input-error {
  border-color: var(--error-color);
}

.react-tel-input .form-control.react-phone-input-error:focus {
  border-color: var(--error-color);
  /* box-shadow: 0 0 0 1px var(--error-color); */
}

.react-tel-input .selected-flag .arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.54);
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  transition: none !important;
}

.MuiTooltip-tooltipPlacementBottom {
  margin: 6px 0 !important;
}
